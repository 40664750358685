<template>
  <div class="container-fluid mb-4 custom-calendar custom-calendar-bookings">
    <v-row class="mb-2">
      <v-tabs v-model="tab" background-color="transparent" color="secondary" style="margin-top: 0" grow show-arrows>
        <v-tab v-for="(tabItem, tabIndex) in tabs" :key="tabIndex">
          {{ str[tabItem] ? str[tabItem] : tabItem }}
        </v-tab>
      </v-tabs>
    </v-row>
    <v-sheet v-if="tabs[tab] === 'calendar'">
      <div class="custom-calendar-header">
        <v-icon color="default" class="cursor-hover" @click="$refs.calendar.prev()">mdi-chevron-left-circle</v-icon>
        <div class="custom-calendar-title row-full-width">
          <h3>
            {{ $refs.calendar && $refs.calendar.title ? $refs.calendar.title : title }}
          </h3>
        </div>
        <v-icon v-if="editPermission" color="success" class="cursor-hover" @click="cloneWeek()">mdi-content-duplicate</v-icon>
        <v-icon color="default" class="cursor-hover" @click="$refs.calendar.next()">mdi-chevron-right-circle</v-icon>
      </div>
      <div v-if="pendingBookings.length" class="pb-3">
        <v-btn color="warning" @click="dialogPendingBookings = true">
          {{ str['booking_pending_count'] }}:
          {{ pendingBookings.length + ' ' + (pendingBookings.length === 1 ? str['booking'] : str['bookings']).toLowerCase() }}
        </v-btn>
      </div>
    </v-sheet>
    <v-sheet v-if="tabs[tab] === 'calendar' && startHour">
      <v-calendar
        ref="calendar"
        v-model="value"
        :locale="language"
        :weekdays="weekday"
        type="week"
        :events="events"
        event-overlap-mode="stack"
        :interval-height="80"
        :first-time="startHour"
        :interval-count="intervalCount"
        :interval-minutes="intervalMinutes"
        :event-color="getEventColor"
        @click:event="openEvent"
        @click:date="openDay"
        @click:time="openTime"
        @change="getEvents"
      >
        <template slot="event" slot-scope="{ event }">
          <div
            class="pa-2"
            style="white-space: initial; overflow: hidden; height: 100%"
            :class="[
              bookings.filter((b) => b.booking_schedule_id === event.id && b.status === bookingStatusDict.pending.value).length
                ? 'yellow darken-2'
                : '',
            ]"
          >
            <div class="mb-1 row-flex-align-center">
              <v-icon
                :color="itemCopied && itemCopied.id === event.id ? 'warning' : 'success'"
                class="cursor-hover pl-1 pr-1"
                @click.stop="copyItem(event)"
              >
                mdi-content-copy
              </v-icon>
              <v-icon color="white" class="cursor-hover pl-1 pr-1">mdi-note-edit</v-icon>
              <v-icon color="error" class="cursor-hover pl-1 pr-1" @click.stop="deleteItem(event)">mdi-delete</v-icon>
            </div>
            <h4 class="text-center white--text ellipsis-rows-1">
              {{ event.type ? event.type : event.title }}
            </h4>
            <div class="row-flex-align-center mt-1" @click.stop="openItemBookings(event)">
              <div
                class="mr-2"
                :class="{
                  'custom-opacity': !bookings.filter(
                    (b) => b.booking_schedule_id === event.id && b.status === bookingStatusDict.confirmed.value,
                  ).length,
                }"
              >
                <v-icon color="success" class="cursor-hover">mdi-check-circle</v-icon>
                <span class="success--text">
                  {{ bookings.filter((b) => b.booking_schedule_id === event.id && b.status === bookingStatusDict.confirmed.value).length }}
                </span>
              </div>
              <div
                :class="{
                  'custom-opacity': !bookings.filter(
                    (b) => b.booking_schedule_id === event.id && b.status === bookingStatusDict.pending.value,
                  ).length,
                }"
              >
                <v-icon color="warning" class="cursor-hover">mdi-alert-circle</v-icon>
                <span class="warning--text">
                  {{ bookings.filter((b) => b.booking_schedule_id === event.id && b.status === bookingStatusDict.pending.value).length }}
                </span>
              </div>
            </div>
          </div>
        </template>
      </v-calendar>
    </v-sheet>

    <v-sheet v-if="tabs[tab] === 'configurations'" class="pb-4 pl-4 pr-4">
      <v-row>
        <v-col
          v-for="item in configFields"
          v-show="item.visible"
          :key="item.id"
          :cols="item.cols ? item.cols : 12"
          class="pt-2"
          :class="{
            'custom-disabled': item.disabled && !item.parent,
            'pb-0': item.parent,
            'pt-8': item.divider,
            'row-flex-column': item.type !== 'checkbox',
          }"
        >
          <v-divider v-if="item.divider" class="divider-light-color pb-4"></v-divider>
          <h4 v-if="item.parent" class="mt-4 mb-1">
            {{ item.parent }}
          </h4>
          <div v-if="item.title && item.type !== 'checkbox'" class="row-full-height input-label">
            {{ item.title + (item.required ? '*' : '') }}
          </div>
          <v-text-field v-if="item.type === 'input' && item.inputType !== 'integer'" v-model="item.value" class="pt-0" hide-details />
          <v-text-field
            v-if="item.type === 'input' && item.inputType === 'integer'"
            v-model="item.value"
            oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
            class="pt-0"
            hide-details
          />
          <v-autocomplete
            v-if="item.type === 'select'"
            v-model="item.value"
            item-text="label"
            item-value="value"
            :items="item.items"
            :no-data-text="str['no_data']"
            :search-input.sync="item.searchInput"
            hide-details
            @change="item.searchInput = ''"
          />
          <v-checkbox
            v-if="item.type === 'checkbox'"
            v-model="item.value"
            :label="item.title + (item.required ? '*' : '')"
            class="form-field-checkbox mt-3"
            hide-details
            @change="item.callback"
          />
          <div v-if="item.type === 'timepicker'">
            <timepicker-inline
              :value="item.value"
              :callback="
                (value) => {
                  item.value = value
                }
              "
            />
          </div>
        </v-col>
        <v-col>
          <div class="mt-4 row-align-right">
            <v-btn color="success" :disabled="!configFieldsValidated()" @click="saveConfig()">
              {{ str['save'] }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-sheet>

    <v-dialog v-if="dialogItem" v-model="dialogItem" max-width="700px">
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ itemToEdit ? str['update_booking_schedule'] : str['new_booking_schedule'] }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-container style="min-height: 583px">
            <v-row>
              <v-col v-if="itemToEdit" cols="12">
                <v-tabs
                  v-model="dialogItemTab"
                  background-color="transparent"
                  color="secondary"
                  style="margin-top: 0"
                  grow
                  show-arrows
                  @change="changeDialogTab"
                >
                  <v-tab v-for="(tabItem, tabIndex) in dialogItemTabs" :key="tabIndex">
                    {{ str[tabItem] ? str[tabItem] : tabItem }}
                  </v-tab>
                </v-tabs>
              </v-col>
            </v-row>
            <v-row v-if="dialogItemTabs[dialogItemTab] === 'settings' || !itemToEdit">
              <v-col
                v-for="item in fields"
                v-show="item.visible"
                :key="item.id"
                :cols="item.cols ? item.cols : 12"
                class="pt-0"
                :class="{ 'custom-disabled': item.disabled }"
              >
                <v-text-field
                  v-if="item.type === 'input' && item.inputType !== 'integer'"
                  v-model="item.value"
                  :label="item.title + (item.required ? '*' : '')"
                  hide-details
                />
                <v-text-field
                  v-if="item.type === 'input' && item.inputType === 'integer'"
                  v-model="item.value"
                  :label="item.title + (item.required ? '*' : '')"
                  oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                  hide-details
                />
                <v-textarea
                  v-if="item.type === 'textarea'"
                  v-model="item.value"
                  :label="item.title + (item.required ? '*' : '')"
                  hide-details
                />
                <v-autocomplete
                  v-if="item.type === 'select'"
                  v-model="item.value"
                  :label="item.title + (item.required ? '*' : '')"
                  item-text="label"
                  item-value="value"
                  :items="item.items"
                  :no-data-text="str['no_data']"
                  :search-input.sync="item.searchInput"
                  hide-details
                  @change="item.searchInput = ''"
                />
                <v-checkbox
                  v-if="item.type === 'checkbox'"
                  v-model="item.value"
                  :label="item.title + (item.required ? '*' : '')"
                  class="form-field-checkbox mt-3"
                  hide-details
                  @change="item.callback"
                />
                <v-menu
                  v-if="item.type === 'datepicker'"
                  v-model="item.menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="item.value"
                      :label="item.title + (item.required ? '*' : '')"
                      readonly
                      v-bind="attrs"
                      hide-details
                      v-on="on"
                    />
                  </template>
                  <v-date-picker v-model="item.value" style="margin: 0" :locale="datepickerLanguage" @change="item.menu = false" />
                </v-menu>
                <div v-if="item.type === 'timepicker'" class="row-align-right">
                  <timepicker-inline
                    :label="item.title + (item.required ? '*' : '')"
                    :value="item.value"
                    :callback="
                      (value) => {
                        item.value = value
                      }
                    "
                    :inline="true"
                  />
                </div>
                <v-menu
                  v-if="item.type === 'colorpicker'"
                  v-model="item.menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template #activator="{ on, attrs }">
                    <div class="row-flex-align mt-3">
                      <label style="margin-right: 10px">
                        {{ item.title }}
                      </label>
                      <div
                        :style="{ backgroundColor: item.value }"
                        style="width: 25px; height: 25px; border-radius: 100%"
                        v-bind="attrs"
                        v-on="on"
                      ></div>
                    </div>
                  </template>
                  <v-color-picker v-model="item.value" style="margin: 0" @change="item.menu = false" />
                </v-menu>
              </v-col>
            </v-row>
            <v-row v-if="dialogItemTabs[dialogItemTab] === 'bookings' && itemToEdit">
              <v-col cols="12">
                <v-btn color="success" @click="openNewBooking()">
                  {{ str['new_booking'] }}
                </v-btn>
              </v-col>
              <v-col v-if="!bookingsFiltered.length" cols="12" class="text-center primary--text">
                {{ str['empty_bookings'] }}
              </v-col>
              <v-col v-for="item in bookingsFiltered" :key="item.id" cols="12">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      <b>
                        {{ str['client'] + ' ' + (clientsDict[item.client_id] ? clientsDict[item.client_id] : item.client_id) }}
                      </b>
                    </v-list-item-title>
                    <v-list-item-subtitle :class="[bookingStatusDict[item.status] ? bookingStatusDict[item.status].type + '--text' : '']">
                      {{
                        bookingStatusDict[item.status]
                          ? str[bookingStatusDict[item.status].label]
                            ? str[bookingStatusDict[item.status].label]
                            : bookingStatusDict[item.status].label
                          : ''
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action v-if="editPermission" class="list-item-actions row-flex-bottom-nowrap">
                    <v-icon
                      v-if="item.status !== bookingStatusDict.confirmed.value"
                      class="mr-2 mb-0 cursor-hover"
                      color="success"
                      @click.stop="updateBookingStatus(item, bookingStatusDict.confirmed.value)"
                    >
                      mdi-check-circle
                    </v-icon>
                    <v-icon
                      v-if="item.status !== bookingStatusDict.canceled.value"
                      class="mr-0 cursor-hover"
                      color="error"
                      @click.stop="updateBookingStatus(item, bookingStatusDict.canceled.value)"
                    >
                      mdi-close
                    </v-icon>
                  </v-list-item-action>
                </v-list-item>
                <v-divider></v-divider>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="default" @click="closeItemDialog()">
            {{ str['cancel'] }}
          </v-btn>
          <v-btn v-if="itemToEdit" color="error" @click="deleteItem(itemToEdit)">
            {{ str['delete_booking_schedule'] }}
          </v-btn>
          <v-btn color="success" :disabled="!fieldsValidated()" @click="saveItem()">
            {{ itemToEdit ? str['save_booking_schedule'] : str['save'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="dialogPendingBookings" v-model="dialogPendingBookings" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ str['booking_pending_count'] }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col v-if="!pendingBookings.length" cols="12" class="text-center primary--text">
                {{ str['empty_pending_bookings'] }}
              </v-col>
              <v-col v-for="item in pendingBookings" :key="item.id" cols="12">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      <b>
                        {{ item.type }}:
                        {{ str['client'] + ' ' + (clientsDict[item.client_id] ? clientsDict[item.client_id] : item.client_id) }}
                      </b>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ item.subtitle }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action v-if="editPermission" class="list-item-actions row-flex-bottom-nowrap">
                    <v-icon
                      v-if="item.status !== bookingStatusDict.confirmed.value"
                      class="mr-2 mb-0 cursor-hover"
                      color="success"
                      @click.stop="updateBookingStatus(item, bookingStatusDict.confirmed.value)"
                    >
                      mdi-check-circle
                    </v-icon>
                    <v-icon
                      v-if="item.status !== bookingStatusDict.canceled.value"
                      class="mr-0 cursor-hover"
                      color="error"
                      @click.stop="updateBookingStatus(item, bookingStatusDict.canceled.value)"
                    >
                      mdi-close
                    </v-icon>
                  </v-list-item-action>
                </v-list-item>
                <v-divider></v-divider>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="default" @click="dialogPendingBookings = false">
            {{ str['close'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="dialogWeek" v-model="dialogWeek" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ str['week'] }} {{ $refs.calendar.lastStart.date }} /
            {{ $refs.calendar.lastEnd.date }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-container class="modal-scroll custom-scroll pt-0 pb-0">
            <v-col cols="12" class="pt-0 pb-2">
              <v-checkbox
                v-model="selectAllWeeks"
                :label="str['select_all']"
                class="form-field-checkbox mt-3"
                hide-details
                @change="
                  selectAllWeeks
                    ? weeks.forEach((w) => {
                        w.value = false
                      })
                    : weeks.forEach((w) => {
                        w.value = true
                      })
                "
              />
            </v-col>
            <v-col v-for="(week, weekIndex) in weeks" :key="weekIndex" cols="12" class="pt-0 pb-0">
              <v-checkbox
                v-model="week.value"
                :label="str['week'] + ' ' + week.start + ' / ' + week.end"
                class="form-field-checkbox mt-3"
                hide-details
              />
            </v-col>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="default" @click="closeWeekDialog()">
            {{ str['cancel'] }}
          </v-btn>
          <v-btn color="success" :disabled="!weeks.filter((w) => w.value).length ? true : false" @click="saveWeek()">
            {{ str['duplicate'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="dialogBooking" v-model="dialogBooking" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ str['new_booking'] }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-container class="pt-0 pb-0">
            <v-col cols="12">
              <v-autocomplete
                v-model="bookingClient"
                :label="str['client']"
                item-text="label"
                item-value="value"
                :items="filteredClients"
                :no-data-text="str['empty_clients_list']"
                :search-input.sync="clientsSearchInput"
                hide-details
                @change="clientsSearchInput = ''"
              />
            </v-col>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="default" @click="dialogBooking = false">
            {{ str['cancel'] }}
          </v-btn>
          <v-btn color="success" :disabled="!bookingClient" @click="newBooking()">
            {{ str['save'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Api from '@/services/Api'
import Utils from '@/services/Utils'
import moment from 'moment'

export default {
  name: 'Bookings',
  components: {
    TimepickerInline: () => import('@/components/dashboard/timepicker/TimepickerInline'),
  },
  data() {
    const user = Utils.getUser()
    return {
      user: user,
      str: window.strings,
      datepickerLanguage: window.datepickerLanguage,
      language: window.language,
      editPermission: Utils.hasPermission('bookings_edit') ? true : false,
      statusItems: Utils.getBookingScheduleStatus(),
      statusDict: this.getBookingScheduleStatusDict(),
      bookingStatusDict: this.getBookingStatusDict(),
      typesItems: Utils.getBookingScheduleTypes(),
      typesDict: this.getBookingScheduleTypesDict(),
      events: [],
      cacheDates: null,
      weekday: [1, 2, 3, 4, 5, 6, 0],
      weekdays: [
        {
          label: window.strings['mon_sun'],
          value: [1, 2, 3, 4, 5, 6, 0],
        },
        {
          label: window.strings['mon_fri'],
          value: [1, 2, 3, 4, 5],
        },
      ],
      clientsActiveStatus: 0,
      value: '',
      dialogItem: false,
      fields: [],
      itemToEdit: null,
      title: '',
      itemCopied: null,
      startHour: null,
      intervalMinutes: null,
      intervalCount: null,
      times: null,
      dialogItemTabs: ['settings', 'bookings'],
      dialogItemTab: 0,
      dialogWeek: false,
      weeks: [],
      bookings: [],
      dialogBooking: false,
      bookingClient: null,
      clientsSearchInput: '',
      clientsDict: {},
      clients: [],
      dialogPendingBookings: false,
      pendingBookings: [],
      configFields: [
        {
          parent: window.strings['general_settings'],
          visible: true,
          disabled: true,
          cols: 12,
        },
        {
          type: 'timepicker',
          id: 'start_time',
          title: window.strings['start_time'],
          value: '',
          required: true,
          visible: true,
          disabled: false,
          cols: 6,
        },
        {
          type: 'timepicker',
          id: 'end_time',
          title: window.strings['end_time'],
          value: '',
          required: true,
          visible: true,
          disabled: false,
          cols: 6,
        },
        {
          type: 'input',
          inputType: 'integer',
          id: 'booking_interval',
          title: window.strings['booking_interval'],
          value: '',
          required: true,
          visible: true,
          disabled: false,
          cols: 4,
        },
        {
          type: 'input',
          inputType: 'integer',
          id: 'daily_limit',
          title: window.strings['daily_limit'],
          value: '',
          required: false,
          visible: true,
          disabled: false,
          cols: 4,
        },
        {
          type: 'input',
          inputType: 'integer',
          id: 'reschedule_limit',
          title: window.strings['reschedule_limit'],
          value: '',
          required: false,
          visible: true,
          disabled: false,
          cols: 4,
        },
        {
          parent: window.strings['booking_schedule_default_settings'],
          divider: true,
          visible: true,
          disabled: true,
          cols: 12,
        },
        {
          type: 'input',
          inputType: 'integer',
          id: 'max_participants',
          title: window.strings['max_participants'],
          value: '',
          required: true,
          visible: true,
          disabled: false,
          cols: 3,
        },
        {
          type: 'input',
          inputType: 'integer',
          id: 'booking_lead_time',
          title: window.strings['booking_lead_time'],
          value: '',
          required: false,
          visible: true,
          disabled: false,
          cols: 3,
        },
        {
          type: 'input',
          inputType: 'integer',
          id: 'booking_limit_time',
          title: window.strings['booking_limit_time'],
          value: '',
          required: false,
          visible: true,
          disabled: false,
          cols: 3,
        },
        {
          type: 'input',
          inputType: 'integer',
          id: 'cancel_limit_time',
          title: window.strings['cancel_limit_time'],
          value: '',
          required: false,
          visible: true,
          disabled: false,
          cols: 3,
        },
        {
          type: 'checkbox',
          id: 'waitlist_enabled',
          title: window.strings['waitlist_enabled'],
          value: '',
          required: false,
          visible: true,
          disabled: false,
          cols: 6,
          callback: (value) => {
            for (let i = 0; i < this.configFields.length; i++) {
              if (this.configFields[i].id === 'waitlist_max_participants') {
                this.configFields[i].disabled = !value ? true : false
                break
              }
            }
          },
        },
        {
          type: 'input',
          inputType: 'integer',
          id: 'waitlist_max_participants',
          title: window.strings['waitlist_max_participants'],
          value: '',
          required: false,
          visible: true,
          disabled: false,
          cols: 6,
        },
      ],
      tabs: ['calendar', 'configurations'],
      tab: 0,
    }
  },
  computed: {
    selectAllWeeks() {
      return this.weeks.filter((w) => w.value).length === this.weeks.length
    },
    bookingsFiltered() {
      return this.itemToEdit ? this.bookings.filter((item) => item.booking_schedule_id === this.itemToEdit.id) : []
    },
    filteredClients() {
      const bookings = this.itemToEdit ? this.bookings.filter((item) => item.booking_schedule_id === this.itemToEdit.id) : this.bookings
      const clientsIds = [...new Set(bookings.map((b) => b.client_id))]
      return this.clients.filter((c) => !clientsIds.includes(c.value))
    },
  },
  beforeMount: function () {
    if (!this.user) {
      return false
    }
    this.getConfig(() => {
      this.getCalendarTimes()
      this.setFields()
      this.getClients()
      setTimeout(() => {
        this.getPendingBookings()
      })
    })
  },
  methods: {
    getConfig: function (callback) {
      this.$isLoading(true)
      Api.getBookingRules((response) => {
        this.$isLoading(false)
        if (response.success) {
          this.config = response.data[0]
          this.refreshConfigFields()
          if (callback) {
            callback()
          }
        } else {
          this.$alert(response.message, window.strings['warning'], 'warning', Utils.getAlertOptions())
        }
      })
    },
    configFieldsValidated() {
      for (let i = 0; i < this.configFields.length; i++) {
        if (this.configFields[i].required && !this.configFields[i].disabled && !this.configFields[i].value) {
          return false
        }
      }
      return true
    },
    saveConfig: function () {
      const data = {}
      for (let i = 0; i < this.configFields.length; i++) {
        if (!this.configFields[i].visible) {
          data[this.configFields[i].id] = this.configFields[i].inputType === 'integer' ? 0 : ''
          continue
        }
        if (this.configFields[i].inputType === 'integer') {
          data[this.configFields[i].id] = parseInt(this.configFields[i].value)
          continue
        }
        if (this.configFields[i].type === 'checkbox') {
          data[this.configFields[i].id] = this.configFields[i].value ? 1 : 0
          continue
        }
        data[this.configFields[i].id] = this.configFields[i].value
      }
      this.$isLoading(true)
      Api.updateBookingRules(data, (response) => {
        this.$isLoading(false)
        if (response.success) {
          this.config = response.data
          this.refreshConfigFields()
        } else {
          this.$alert(response.message, window.strings['warning'], 'warning', Utils.getAlertOptions())
        }
      })
    },
    refreshConfigFields() {
      for (let i = 0; i < this.configFields.length; i++) {
        this.configFields[i].value = this.config && this.config[this.configFields[i].id] ? this.config[this.configFields[i].id] : ''
        if (this.configFields[i].callback) {
          this.configFields[i].callback(this.configFields[i].value)
        }
      }
    },
    getCalendarTimes: function () {
      const startHour = this.config && this.config.start_time ? this.config.start_time : '06:00'
      const endHour = this.config && this.config.end_time ? this.config.end_time : '20:00'
      const intervalMinutes = this.config && this.config.booking_interval ? this.config.booking_interval : 60
      const [startH, startM] = startHour.split(':').map(Number)
      const [endH, endM] = endHour.split(':').map(Number)
      const startInMinutes = startH * 60 + startM
      const endInMinutes = endH * 60 + endM
      const totalMinutes = endInMinutes - startInMinutes
      const intervalCount = Math.floor(totalMinutes / intervalMinutes)
      const times = []
      for (let i = 0; i < intervalCount; i++) {
        const timeStart = startInMinutes + i * intervalMinutes
        const timeEnd = timeStart + intervalMinutes
        const start = `${String(Math.floor(timeStart / 60)).padStart(2, '0')}:${String(timeStart % 60).padStart(2, '0')}`
        const end = `${String(Math.floor(timeEnd / 60)).padStart(2, '0')}:${String(timeEnd % 60).padStart(2, '0')}`
        times.push({ start, end })
      }
      this.intervalMinutes = intervalMinutes
      this.intervalCount = intervalCount
      this.times = times
      this.startHour = startHour
    },
    getBookingScheduleStatusDict: function () {
      const list = Utils.getBookingScheduleStatus()
      const dict = {}
      if (list && list.length) {
        list.forEach(function (item) {
          dict[item.value] = item.value
        })
      }
      return dict
    },
    getBookingStatusDict: function () {
      const list = Utils.getBookingStatus()
      const dict = {}
      if (list && list.length) {
        list.forEach(function (item) {
          dict[item.value] = item
        })
      }
      return dict
    },
    getBookingScheduleTypesDict: function () {
      const list = Utils.getBookingScheduleTypes()
      const dict = {}
      if (list && list.length) {
        list.forEach(function (item) {
          dict[item.value] = item
        })
      }
      return dict
    },
    setFields: function () {
      const fields = [
        {
          type: 'select',
          id: 'type',
          title: window.strings['type'],
          value: '',
          defaultValue: this.typesItems && this.typesItems.length ? this.typesItems[0].value : '',
          items: this.typesItems && this.typesItems.length ? this.typesItems : [],
          required: true,
          visible: this.typesItems && this.typesItems.length ? true : false,
          disabled: false,
          cols: 12,
        },
        {
          type: 'input',
          id: 'title',
          title: window.strings['title'],
          defaultValue: '',
          value: '',
          required: false,
          visible: !this.typesItems || (this.typesItems && !this.typesItems.length) ? true : false,
          disabled: false,
          cols: 12,
        },
        {
          type: 'datepicker',
          id: 'start_date',
          title: window.strings['start_date'],
          defaultValue: '',
          value: '',
          required: true,
          visible: true,
          disabled: false,
          menu: false,
          cols: 6,
        },
        {
          type: 'timepicker',
          id: 'start_hour',
          title: window.strings['start_hour'],
          defaultValue: '',
          value: '',
          required: true,
          visible: true,
          disabled: false,
          cols: 6,
        },
        {
          type: 'datepicker',
          id: 'end_date',
          title: window.strings['end_date'],
          defaultValue: '',
          value: '',
          required: true,
          visible: true,
          disabled: false,
          menu: false,
          cols: 6,
        },
        {
          type: 'timepicker',
          id: 'end_hour',
          title: window.strings['end_hour'],
          defaultValue: '',
          value: '',
          required: true,
          visible: true,
          disabled: false,
          cols: 6,
        },
        {
          type: 'input',
          inputType: 'integer',
          id: 'max_participants',
          title: window.strings['max_participants'],
          defaultValue: this.config && this.config.max_participants ? this.config.max_participants : '',
          value: '',
          required: true,
          visible: true,
          disabled: false,
          cols: 6,
        },
        {
          type: 'input',
          inputType: 'integer',
          id: 'booking_lead_time',
          title: window.strings['booking_lead_time'],
          defaultValue: this.config && this.config.booking_lead_time ? this.config.booking_lead_time : '',
          value: '',
          required: true,
          visible: true,
          disabled: false,
          cols: 6,
        },
        {
          type: 'input',
          inputType: 'integer',
          id: 'booking_limit_time',
          title: window.strings['booking_limit_time'],
          defaultValue: this.config && this.config.booking_limit_time ? this.config.booking_limit_time : '',
          value: '',
          required: true,
          visible: true,
          disabled: false,
          cols: 6,
        },
        {
          type: 'input',
          inputType: 'integer',
          id: 'cancel_limit_time',
          title: window.strings['cancel_limit_time'],
          defaultValue: this.config && this.config.cancel_limit_time ? this.config.cancel_limit_time : '',
          value: '',
          required: true,
          visible: true,
          disabled: false,
          cols: 6,
        },
        {
          type: 'checkbox',
          id: 'waitlist_enabled',
          title: window.strings['waitlist_enabled'],
          callback: (value) => {
            for (let i = 0; i < this.fields.length; i++) {
              if (this.fields[i].id === 'waitlist_max_participants') {
                this.fields[i].disabled = !value ? true : false
                break
              }
            }
          },
          defaultValue: this.config && this.config.waitlist_enabled ? this.config.waitlist_enabled : '',
          value: '',
          required: false,
          visible: true,
          disabled: false,
          cols: 6,
        },
        {
          type: 'input',
          inputType: 'integer',
          id: 'waitlist_max_participants',
          title: window.strings['waitlist_max_participants'],
          defaultValue: this.config && this.config.waitlist_max_participants ? this.config.waitlist_max_participants : '',
          value: '',
          required: true,
          visible: true,
          disabled: false,
          cols: 6,
        },
        {
          type: 'textarea',
          id: 'description',
          title: window.strings['description'],
          defaultValue: '',
          value: '',
          required: false,
          visible: true,
          disabled: false,
          cols: 12,
        },
      ]
      this.fields = fields
    },
    getClients: function () {
      this.$isLoading(true)
      Api.getClients(
        {
          status: this.clientsActiveStatus,
          fields: ['id', 'name'],
          useCache: true,
        },
        (response) => {
          this.$isLoading(false)
          if (response.success) {
            const clients = []
            const clientsDict = {}
            for (let i = 0; i < response.data.length; i++) {
              clients.push({
                value: response.data[i].id,
                label: response.data[i].id + ' - ' + response.data[i].name,
              })
              clientsDict[response.data[i].id] = response.data[i].id + ' - ' + response.data[i].name
            }
            this.clientsDict = clientsDict
            this.clients = clients
          } else {
            this.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    getEvents(args) {
      if (!this.user) {
        return false
      }
      this.$isLoading(true)
      if (args) {
        this.cacheDates = {
          start_date: moment(args.start.date).subtract(1, 'days').format('YYYY-MM-DD'),
          end_date: moment(args.end.date).add(1, 'days').format('YYYY-MM-DD'),
        }
      }
      Api.getBookingSchedule(this.cacheDates, (response) => {
        this.$isLoading(false)
        if (response.success) {
          this.events = this.encodeEvents(response.data)
          this.refreshBookings()
        } else {
          this.$alert(response.message, window.strings['warning'], 'warning', Utils.getAlertOptions())
        }
      })
    },
    encodeEvents(data) {
      const events = []
      for (let i = 0; i < data.length; i++) {
        const obj = this.encodeEvent(data[i])
        if (obj) {
          events.push(obj)
        }
      }
      return events
    },
    encodeEvent(event) {
      if (event.start_date && event.end_date) {
        return {
          id: event.id,
          status: event.status,
          title: event.title,
          start: event.start_date.replace(' ', 'T') + ':00',
          end: event.end_date.replace(' ', 'T') + ':00',
          start_hour: event.start_date.split('T')[1],
          end_hour: event.end_date.split('T')[1],
          description: event.description,
          type: this.typesDict[event.type]
            ? this.str[this.typesDict[event.type].label]
              ? this.str[this.typesDict[event.type].label]
              : this.typesDict[event.type].label
            : 'teste',
          max_participants: event.max_participants,
          waitlist_enabled: event.waitlist_enabled,
          waitlist_max_participants: event.waitlist_max_participants,
          booking_lead_time: event.booking_lead_time,
          booking_limit_time: event.booking_limit_time,
          cancel_limit_time: event.cancel_limit_time,
          color: this.typesDict[event.type] ? this.typesDict[event.type].color : '',
        }
      } else {
        return false
      }
    },
    refreshBookings() {
      const ids = this.itemToEdit ? [this.itemToEdit.id] : this.events.map((item) => item.id)
      if (!ids.length) {
        this.bookings = []
        return
      }
      Api.getBooking(
        {
          booking_schedule_ids: ids,
        },
        (response) => {
          if (response.success) {
            this.bookings = response.data ? response.data : []
          } else {
            this.$alert(response.message, window.strings['warning'], 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    getPendingBookings() {
      Api.getBooking(
        {
          status: this.bookingStatusDict.pending.value,
        },
        (response) => {
          if (response.success) {
            this.pendingBookings = response.data
              ? response.data.map((item) => ({
                  ...item,
                  subtitle: `${moment(item.start_date).format('DD/MM/YYYY')}, ${moment(item.start_date).format('HH:mm')} - ${moment(item.end_date).format('HH:mm')}`,
                }))
              : []
          } else {
            this.$alert(response.message, window.strings['warning'], 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    getEventColor(event) {
      return event.color
    },
    openDay() {
      this.type = 'day'
    },
    openTime(event) {
      if (this.editPermission) {
        const interval = this.times.find((t) => {
          return moment(event.time, 'HH:mm').isBetween(moment(t.start, 'HH:mm'), moment(t.end, 'HH:mm'), null, '[)')
        })
        if (interval) {
          this.timeOpened = event
            ? {
                day: event.date,
                start: interval.start,
                end: interval.end,
              }
            : null
          if (this.itemCopied) {
            return this.pasteItem()
          }
          this.itemToEdit = null
          this.setData()
          this.openDialog()
        }
      }
    },
    openEvent(evt) {
      if (this.editPermission) {
        this.itemToEdit = evt.event
        this.setData()
        this.openDialog()
        evt.nativeEvent.stopPropagation()
      }
    },
    openItemBookings(item) {
      if (this.editPermission) {
        this.itemToEdit = item
        this.setData()
        this.openDialog(true)
      }
    },
    openDialog(openBookingsTab) {
      this.itemInitialData = JSON.stringify(this.getData())
      this.dialogItemTab = openBookingsTab ? 1 : 0
      this.dialogItem = true
    },
    changeDialogTab() {
      if (this.itemInitialData !== JSON.stringify(this.getData())) {
        this.saveItem(true)
      }
    },
    fieldsValidated() {
      let dateStart = ''
      let hourStart = ''
      let dateEnd = ''
      let hourEnd = ''
      for (let i = 0; i < this.fields.length; i++) {
        if (this.fields[i].required && this.fields[i].visible && !this.fields[i].disabled && !this.fields[i].value) {
          return false
        }
        if (this.fields[i].id === 'start_date') {
          dateStart = this.fields[i].value
          continue
        }
        if (this.fields[i].id === 'start_hour') {
          hourStart = this.fields[i].value
          continue
        }
        if (this.fields[i].id === 'end_date') {
          dateEnd = this.fields[i].value
          continue
        }
        if (this.fields[i].id === 'end_hour') {
          hourEnd = this.fields[i].value
          continue
        }
      }
      const startDate = dateStart + ' ' + hourStart
      const endDate = dateEnd + ' ' + hourEnd
      if (startDate > endDate) {
        return false
      }
      return true
    },
    saveItem(keepOpened) {
      const data = this.getData()
      if (this.saveValidation(data)) {
        this.$isLoading(true)
        if (this.itemToEdit) {
          Api.updateBookingSchedule(data, (response) => {
            this.$isLoading(false)
            if (response.success) {
              if (!keepOpened) {
                this.closeItemDialog()
              }
              this.getEvents()
            } else {
              this.$alert(response.message, window.strings['warning'], 'warning', Utils.getAlertOptions())
            }
          })
        } else {
          data.status = this.statusDict.active
          Api.newBookingSchedule(data, (response) => {
            this.$isLoading(false)
            if (response.success) {
              if (!keepOpened) {
                this.closeItemDialog()
              }
              this.getEvents()
            } else {
              this.$alert(response.message, window.strings['warning'], 'warning', Utils.getAlertOptions())
            }
          })
        }
      }
    },
    saveValidation(data) {
      try {
        for (let i = 0; i < this.events.length; i++) {
          const existingEvent = this.events[i]

          if (data.id === existingEvent.id) {
            continue
          }

          const newStart = moment(data.start_date, 'YYYY-MM-DDTHH:mm:ss')
          const newEnd = moment(data.end_date, 'YYYY-MM-DDTHH:mm:ss')
          const existingStart = moment(existingEvent.start, 'YYYY-MM-DDTHH:mm:ss')
          const existingEnd = moment(existingEvent.end, 'YYYY-MM-DDTHH:mm:ss')

          if (
            newStart.isBetween(existingStart, existingEnd, null, '[)') ||
            newEnd.isBetween(existingStart, existingEnd, null, '(]') ||
            (newStart.isSameOrBefore(existingStart) && newEnd.isSameOrAfter(existingEnd))
          ) {
            this.$alert(window.strings['save_booking_schedule_failed'], window.strings['warning'], 'warning', Utils.getAlertOptions())
            return false
          }
        }
      } catch {}

      return true
    },
    setData(dataCopied) {
      const src = dataCopied ? dataCopied : this.itemToEdit
      const defaultDate =
        this.type === 'day' && this.$refs.calendar && this.$refs.calendar.value
          ? this.$refs.calendar.value
          : this.timeOpened
            ? this.timeOpened.day
            : moment().format('YYYY-MM-DD')
      const start = src && src.start && src.start.includes('T') ? src.start.split('T') : ''
      const dateStart = start ? start[0] : defaultDate
      const hourStart = start ? start[1].slice(0, -3) : this.timeOpened ? this.timeOpened.start : ''
      const end = src && src.end && src.end.includes('T') ? src.end.split('T') : ''
      const dateEnd = end ? end[0] : defaultDate
      const hourEnd = end ? end[1].slice(0, -3) : this.timeOpened ? this.timeOpened.end : ''
      const hasItemToEditBookings = this.itemToEdit && this.bookingsFiltered.length ? true : false
      for (let i = 0; i < this.fields.length; i++) {
        if (
          this.fields[i].id === 'start_date' ||
          this.fields[i].id === 'start_hour' ||
          this.fields[i].id === 'end_date' ||
          this.fields[i].id === 'end_hour'
        ) {
          this.fields[i].disabled = hasItemToEditBookings ? true : false
        }
        if (this.fields[i].id === 'start_date') {
          this.fields[i].value = dateStart
          continue
        }
        if (this.fields[i].id === 'start_hour') {
          this.fields[i].value = hourStart
          continue
        }
        if (this.fields[i].id === 'end_date') {
          this.fields[i].value = dateEnd
          continue
        }
        if (this.fields[i].id === 'end_hour') {
          this.fields[i].value = hourEnd
          continue
        }
        this.fields[i].value =
          src && src[this.fields[i].id] ? src[this.fields[i].id] : this.fields[i].defaultValue ? this.fields[i].defaultValue : ''
        if (this.fields[i].callback) {
          this.fields[i].callback(this.fields[i].value)
        }
      }
    },
    getData() {
      const data = {}
      let dateStart = ''
      let hourStart = ''
      let dateEnd = ''
      let hourEnd = ''
      for (let i = 0; i < this.fields.length; i++) {
        if (!this.fields[i].visible) {
          data[this.fields[i].id] = this.fields[i].inputType === 'integer' ? 0 : ''
          continue
        }
        if (this.fields[i].id === 'start_date') {
          dateStart = this.fields[i].value
          continue
        }
        if (this.fields[i].id === 'start_hour') {
          hourStart = this.fields[i].value
          continue
        }
        if (this.fields[i].id === 'end_date') {
          dateEnd = this.fields[i].value
          continue
        }
        if (this.fields[i].id === 'end_hour') {
          hourEnd = this.fields[i].value
          continue
        }
        if (this.fields[i].inputType === 'integer') {
          data[this.fields[i].id] = parseInt(this.fields[i].value)
          continue
        }
        if (this.fields[i].type === 'checkbox') {
          data[this.fields[i].id] = this.fields[i].value ? 1 : 0
          continue
        }
        data[this.fields[i].id] = this.fields[i].value
      }
      data.start_date = dateStart + 'T' + hourStart
      data.end_date = dateEnd + 'T' + hourEnd
      data.timezone = moment().utcOffset() / 60
      if (this.itemToEdit) {
        data.id = this.itemToEdit.id
      }
      return data
    },
    deleteItem(item) {
      this.$confirm(window.strings['are_you_sure_delete'], '', 'warning', Utils.getAlertOptions(true, true))
        .then(() => {
          this.$isLoading(true)
          Api.deleteBookingSchedule(
            {
              id: item.id,
            },
            (response) => {
              this.$isLoading(false)
              if (response.success) {
                this.closeItemDialog()
                this.getEvents()
              } else {
                this.$alert(response.message, window.strings['warning'], 'warning', Utils.getAlertOptions())
              }
            },
          )
        })
        .catch(() => {})
    },
    copyItem(item) {
      if (this.itemCopied && this.itemCopied.id === item.id) {
        this.itemCopied = null
      } else {
        this.itemCopied = JSON.parse(JSON.stringify(item))
        this.itemCopied.start = ''
        this.itemCopied.start_hour = ''
        this.itemCopied.end = ''
        this.itemCopied.end_hour = ''
      }
    },
    pasteItem() {
      this.setData(this.itemCopied)
      this.saveItem()
    },
    closeItemDialog() {
      this.dialogItem = false
      this.itemToEdit = null
    },
    cloneWeek() {
      const weeks = []
      let currentStart = new Date(this.$refs.calendar.lastEnd.date)
      currentStart.setDate(currentStart.getDate() - (currentStart.getDay() === 0 ? 6 : currentStart.getDay() - 1) + 7)
      for (let i = 0; i < 52; i++) {
        const start = new Date(currentStart)
        const end = new Date(start)
        end.setDate(end.getDate() + 6)
        weeks.push({
          start: start.toISOString().split('T')[0],
          end: end.toISOString().split('T')[0],
          value: false,
        })
        currentStart.setDate(currentStart.getDate() + 7)
      }
      this.weeks = weeks
      this.dialogWeek = true
    },
    saveWeek() {
      const replicatedEvents = []
      const targetWeeks = this.weeks.filter((w) => {
        return w.value
      })

      targetWeeks.forEach((week) => {
        const weekStartDate = new Date(week.start)
        this.events.forEach((event) => {
          const eventStartDate = new Date(event.start)
          const eventEndDate = new Date(event.end)

          const dayOffset = eventStartDate.getDay() - weekStartDate.getDay()

          const newStartDate = new Date(weekStartDate)
          newStartDate.setDate(weekStartDate.getDate() + dayOffset)
          newStartDate.setHours(eventStartDate.getHours(), eventStartDate.getMinutes(), eventStartDate.getSeconds())

          const newEndDate = new Date(newStartDate)
          newEndDate.setHours(eventEndDate.getHours(), eventEndDate.getMinutes(), eventEndDate.getSeconds())

          replicatedEvents.push({
            ...event,
            start: moment(newStartDate).format('YYYY-MM-DDTHH:mm:ss'),
            end: moment(newEndDate).format('YYYY-MM-DDTHH:mm:ss'),
          })
        })
      })

      if (replicatedEvents.length) {
        this.$isLoading(true)
        return this.createItem(replicatedEvents, 0, () => {
          this.$isLoading(false)
          this.getEvents()
          this.closeWeekDialog()
        })
      }

      this.closeWeekDialog()
    },
    createItem(items, index, callback) {
      if (!items[index]) {
        return callback()
      }
      this.setData(items[index])
      const data = this.getData()
      if (this.saveValidation(data)) {
        data.status = this.statusDict.active
        Api.newBookingSchedule(data, () => {
          this.createItem(items, index + 1, callback)
        })
      } else {
        this.createItem(items, index + 1, callback)
      }
    },
    closeWeekDialog() {
      this.dialogWeek = false
    },
    openNewBooking() {
      this.clientsSearchInput = ''
      this.bookingClient = null
      this.dialogBooking = true
    },
    newBooking() {
      this.$isLoading(true)
      Api.newBooking(
        {
          client_id: this.bookingClient,
          booking_schedule_id: this.itemToEdit.id,
          date: this.itemToEdit.start,
          status: this.bookingStatusDict.confirmed.value,
        },
        (response) => {
          this.$isLoading(false)
          if (response.success) {
            this.dialogBooking = false
            this.refreshBookings()
          } else {
            this.$alert(response.message, window.strings['warning'], 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    updateBookingStatus(item, status) {
      this.$isLoading(true)
      Api.updateBooking(
        {
          id: item.id,
          status: status,
        },
        (response) => {
          this.$isLoading(false)
          if (response.success) {
            this.refreshBookings()
            this.getPendingBookings()
          } else {
            this.$alert(response.message, window.strings['warning'], 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
  },
}
</script>
